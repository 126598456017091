import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Details = () => (
  <Layout className="wedding details">
    <SEO title="Wedding Details" />
    <h1>Wedding Details</h1>
    <div className="box-container">
      <div className="details-item">
        <h2>Ceremony location</h2>
        <p>The wedding ceremony and reception will take place in <strong><a href="https://www.eloundavillamarina.com/" rel="noopener noreferrer" target="_blank">Villa Marina</a></strong>, in the village of Elounda at Agios Nicholaos, Crete. See map below for exact location and directions.</p>
      </div>
      <div className="details-item">
        <h2>Registry</h2>
        <p>
            Your presence at our wedding is the biggest gift we could hope for!<br/>
            However, should you wish to give us a gift or contribute to <a href="https://paypal.me/estherixz" target="_blank">our honeymoon fund</a>,
            you can check out <a href="https://wishlist.com/l/oponqq" target="_blank">our wedding registry</a>.
            We'd kindly ask to not bring anything to Crete as it will be difficult for us to take back home.
            If you really want to send us something please ask for our address.
        </p>
      </div>
      <div className="details-item">
        <h2>Dress Code</h2>
        <p>Garden party / Cocktail</p>
      </div>
      <div className="details-item">
        <h2>Program</h2>
        <ul>
          <li>4pm: Guest arrival / Welcome drinks</li>
          <li>5pm: Wedding Ceremony</li>
          <li>5.30pm: Cocktail hour</li>
          <li>7pm: Dinner & Dancing to the night</li>
        </ul>
      </div>
      <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1628.7010234228226!2d25.721354488452555!3d35.271128995528656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDE2JzE2LjEiTiAyNcKwNDMnMjAuNiJF!5e0!3m2!1sen!2sus!4v1579559784963!5m2!1sen!2sus" frameBorder="0" allowFullScreen />
    </div>
  </Layout>
)

export default Details
